const labels = {
  addNote: 'ADD NOTE',
  note: 'Note',
  save: 'SAVE',
  max1000Chars: 'Max 1000 Chars.',
  cancel: 'CANCEL',
  savedSuccessfully: 'Note saved successfully'
};

export { labels };
