import type { FC } from 'react';
import { OpenInNew } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import { ContainerContent, ContainerHeader, HeaderWrapper, StyledCard } from './Container.styled';
import type { Props } from './types';

const Container: FC<Props> = ({
  id,
  dataTestId,
  title,
  link,
  children,
  style,
  contentStyle,
  header,
  variant = 'secondary',
  ...rest
}) => {
  return (
    <StyledCard variant="outlined" style={style} id={id} data-testid={dataTestId} {...rest}>
      {header ?? (
        <HeaderWrapper>
          <ContainerHeader
            title={
              <>
                {title}
                {link ? (
                  <NavLink to={link} target="_blank">
                    <OpenInNew fontSize="small" />
                  </NavLink>
                ) : null}
              </>
            }
            variant={variant}
          />
        </HeaderWrapper>
      )}
      <ContainerContent style={contentStyle}>{children}</ContainerContent>
    </StyledCard>
  );
};

export { Container };
