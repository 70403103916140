import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import {
  Auth0ContextProvider,
  GlobalContextProvider,
  ReactQueryPersisterContextProvider,
  theme
} from '@linx-ui/shared';
import App from './App';

if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
  navigator.serviceWorker
    .register(`/rx/service-worker.js`)
    .then((registration) => console.log('SW registered: ', registration))
    .catch((registrationError) => console.log('SW registration failed: ', registrationError));
}

if ('Notification' in window) {
  Notification.requestPermission()
    .then((permission) => console.log(`Notification permission ${permission}.`))
    .catch(() => console.log('Unable to request permission for notifications.'));
}

const container = document.getElementById('root');

if (container != null) {
  const root = createRoot(container);

  root.render(
    <BrowserRouter>
      <Auth0ContextProvider>
        <ReactQueryPersisterContextProvider>
          <GlobalContextProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <GlobalStyles
                styles={{
                  html: { width: '100%', height: '100%' },
                  body: {
                    backgroundColor: theme.palette.background.paper,
                    width: '100%',
                    height: '100%'
                  },
                  '#root': {
                    height: '100%',
                    width: '100%'
                  }
                }}
              />
              <App />
            </ThemeProvider>
          </GlobalContextProvider>
        </ReactQueryPersisterContextProvider>
      </Auth0ContextProvider>
    </BrowserRouter>
  );
}
