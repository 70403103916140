import { TEN_DIGITS, WORDS } from '@linx-ui/shared/utils';

const sendSMSValidationRules = {
  phoneNumber: {
    required: true,
    maxLength: 10,
    minLength: { value: 10, message: 'Phone number should be 10 digit' },
    pattern: {
      value: TEN_DIGITS,
      message: 'Can’t contain a text or symbol'
    }
  },
  message: {
    required: { value: true, message: 'Please enter message' },
    maxLength: 250,
    pattern: {
      value: new RegExp(WORDS, 'm'),
      message: 'Please enter valid message'
    }
  }
};

export { sendSMSValidationRules };
