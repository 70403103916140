import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IconButton } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';
import { DragIndicatorStyled, HeaderTableCell } from './CustomDataGrid.styled';
import type { DraggableTableHeaderProps } from './types';

export const DraggableTableHeader: FC<PropsWithChildren<DraggableTableHeaderProps>> = ({
  children,
  header,
  resizer
}) => {
  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: header.column.id
  });
  return (
    <HeaderTableCell
      colSpan={header.colSpan}
      ref={setNodeRef}
      transform={header.column.id !== 'selection-col' ? CSS.Translate.toString(transform) : undefined}
      width={header.getSize()}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        {children}
        {!header.isPlaceholder && !header.subHeaders.length && header.column.id !== 'selection-col' && (
          <IconButton {...attributes} {...listeners} size="small">
            <DragIndicatorStyled />
          </IconButton>
        )}
      </div>
      {resizer}
    </HeaderTableCell>
  );
};
