import { AccessTimeOutlined, Delete, ExpandMore } from '@mui/icons-material';
import { alpha, Box, Button, Grid, Skeleton, styled, Tabs, Typography, type TabsProps } from '@mui/material';
import { DRAWER_WITH_ACTION_WIDTH } from '@linx-ui/shared/constants';
import { theme } from '@linx-ui/shared/theming';

const BackIconWrapper = styled(Grid)(({ theme }) => {
  return {
    marginBottom: theme.spacing(1)
  };
});

const BackAndDetails = styled('div')(({ theme }) => {
  return {
    padding: theme.spacing(3)
  };
});

const TabsStyled = ({ value, onChange, children }: TabsProps): JSX.Element => (
  <Tabs
    sx={{
      flex: 1,
      borderBottom: `1px solid ${theme.palette.divider}`,
      '.MuiTabs-indicator': {
        borderRadius: '2px 2px 0px 0px'
      },
      '.MuiBadge-badge': {
        position: 'unset',
        transform: 'unset'
      }
    }}
    variant="scrollable"
    scrollButtons={false}
    value={value ?? false}
    onChange={onChange}
  >
    {children}
  </Tabs>
);

const ButtonStyled = styled(Button)(({ theme }) => {
  return {
    color: theme.palette.secondary.main,
    gap: theme.spacing(1.25),
    textTransform: 'uppercase',
    fontSize: '13px',
    paddingLeft: 0
  };
});

const Title = styled('div')(({ theme }) => {
  return {
    maxWidth: '85%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary
  };
});

const SubTitle = styled('div')(({ theme }) => {
  return {
    color: theme.palette.text.secondary,
    maxWidth: '95%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  };
});

const Content = styled('div')(() => ({
  height: '100%',
  width: '100%'
}));

const ContentContainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}));

const ScrollableContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasBottomBar' && prop !== 'hasBackground'
})<{ hasBottomBar?: boolean; hasBackground?: boolean }>(({ theme, hasBottomBar = true, hasBackground = true }) => ({
  overflow: 'auto',
  padding: theme.spacing(2),
  background: hasBackground ? theme.palette.background.default : theme.palette.common.white,
  height: `calc(100% - ${hasBottomBar ? 64 : 0}px)`
}));

const DeleteIcon = styled(Delete)(() => ({}));

const StyledDeleteBtn = ({
  onClick,
  disabled,
  children,
  icon
}: {
  onClick: () => void;
  disabled?: boolean;
  children: string;
  icon?: React.ReactNode;
}) => (
  <Button
    variant="outlined"
    data-testid="delete-btn"
    startIcon={icon}
    color="error"
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </Button>
);

const Footer = styled('div')(({ theme }) => ({
  minHeight: '64px',
  padding: theme.spacing(0, 2, 0, 2),
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'center',
  justifyContent: 'flex-end',
  borderTop: `1px solid ${theme.palette.divider}`
}));

const OutletCnt = styled('div')<{ topHeight: number }>(({ topHeight }) => ({
  height: `calc(100% - ${topHeight}px)`
}));

const BoldSpaced = styled('div')(({ theme }) => ({
  fontWeight: 'bold',
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5)
}));

const TimeStampIcon = styled(AccessTimeOutlined)(({ theme }) => ({
  marginRight: theme.spacing(1)
}));

const TimeStampText = styled(Typography)<any>(() => ({
  display: 'flex'
}));

const ChangeHistoryTableCnt = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 2, 0, 2),
  height: '100%',
  backgroundColor: theme.palette.background.default
}));

const RowWrapper = styled('div')(({ theme }) => {
  return {
    '&:not(:last-child)': {
      paddingBottom: theme.spacing(1)
    },
    '&:nth-of-type(n+1)': {
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    '&:last-child': {
      border: 'none'
    }
  };
});

const RootContainerBox = styled(Box)(() => ({
  display: 'flex',
  height: '100%'
}));

const StepperBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  width: `${DRAWER_WITH_ACTION_WIDTH}px`,
  gap: theme.spacing(1.25),
  overflow: 'auto',
  borderRight: `1px solid ${theme.palette.divider}`,
  whiteSpace: 'nowrap',
  boxShadow: `0px 0px 0px 1px ${alpha(theme.palette.primary.contrastText, 0.12)}`
}));

const ScrollableBox = styled(Box)(({ theme }) => ({
  overflow: 'auto',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default
}));

const FormContainer = styled(Box)(({ theme }) => ({
  height: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  width: '100%'
}));

const TextWithEllipis = styled(Typography)(({ theme }) => {
  return {
    width: '99%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary
  };
});

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  variant: 'rectangular',
  height: theme.spacing(4.5),
  borderRadius: '4px'
}));

const ListingContainer = styled('div')(() => {
  return {
    width: '100%',
    height: '100%'
  };
});

const HeaderActions = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  gap: theme.spacing(2)
}));

const ExpandMoreIcon = styled(ExpandMore, {
  shouldForwardProp: (props) => props !== 'isMenuOpen'
})<{ isMenuOpen: boolean }>(({ isMenuOpen }) => ({
  transform: isMenuOpen ? 'rotate(180deg)' : 'none',
  transition: 'all 200ms ease'
}));

export {
  BackAndDetails,
  BackIconWrapper,
  BoldSpaced,
  ButtonStyled,
  ChangeHistoryTableCnt,
  Content,
  ContentContainer,
  ExpandMoreIcon,
  Footer,
  FormContainer,
  HeaderActions,
  ListingContainer,
  OutletCnt,
  RootContainerBox,
  RowWrapper,
  ScrollableBox,
  ScrollableContent,
  StepperBox,
  StyledDeleteBtn,
  StyledSkeleton,
  SubTitle,
  TabsStyled,
  TextWithEllipis,
  TimeStampIcon,
  TimeStampText,
  Title,
  DeleteIcon
};
