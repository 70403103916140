import { Tab } from '@mui/material';
import { useState } from 'react';
import { TabsStyled } from '../styles';
import { TabPanel } from '../tab';
import { TabsContainer, TabsContentContainer } from './TabsCreator.styled';
import type { TabCreatorProps } from './types';

const TabsCreator = ({ items, selected }: TabCreatorProps) => {
  const [expanded, setExpanded] = useState<string>(selected);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    event.stopPropagation();
    setExpanded(newValue);
  };

  return (
    <TabsContainer>
      <TabsStyled value={expanded} onChange={handleChange}>
        {items.map((e) => {
          return <Tab label={e.title} value={e.key} key={e.key} data-testid={`${e.key}-header-tab`} />;
        })}
      </TabsStyled>
      {items.map((e) => {
        return (
          <TabPanel value={expanded} currentTabValue={e.key} key={e.key}>
            <TabsContentContainer>{e.component}</TabsContentContainer>
          </TabPanel>
        );
      })}
    </TabsContainer>
  );
};

export { TabsCreator };
