import { styled } from '@mui/material';

const SendSMSDrawerContent = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  gap: theme.spacing(2),
  flexDirection: 'column',
  height: '100%'
}));

export { SendSMSDrawerContent };
