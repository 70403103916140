import { type SelectOption } from '@linx-ui/shared/components';

const getOptionLabelWithCode = (option: SelectOption) => {
  const values: string[] = [];

  if (option.code) {
    values.push(option.code as string);
  }

  if (option.label) {
    values.push(option.label as string);
  }

  return values.join(' - ');
};

export { getOptionLabelWithCode };
