import { Autocomplete, ListSubheader, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { type AutoCompleteWrapperProps } from './types';

function AutocompleteWrapper<Value = string, Multiple extends boolean | undefined = false>({
  size = 'small',
  ...props
}: AutoCompleteWrapperProps<Value, Multiple>): JSX.Element {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue ?? ''}
      render={({ field: { onChange, ...rest } }) => (
        <Autocomplete<Value, Multiple, false, true>
          sx={props.sx}
          data-testid={props.testId}
          size={size}
          autoComplete
          {...rest}
          multiple={props.multiple}
          options={props.options}
          groupBy={props.groupBy}
          freeSolo
          forcePopupIcon
          clearOnBlur
          loading={props.loading}
          disabled={props.disabled}
          // @ts-expect-error
          getOptionLabel={props.getOptionLabel}
          renderOption={props.renderOption}
          renderTags={props.renderTags}
          onInputChange={props.onInputChange}
          isOptionEqualToValue={props.isOptionEqualToValue}
          filterOptions={props.filterOptions}
          renderGroup={(groupProps) => {
            return (
              <li>
                <ListSubheader sx={{ backgroundColor: 'background.default', top: '-8px' }}>
                  {groupProps.group}
                </ListSubheader>
                <ul>{groupProps.children}</ul>
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{ ...params.InputProps, ...props.inputProps, disableInjectingGlobalStyles: true }}
              error={props.error?.hasError}
              onFocus={() => props.onFocus?.()}
              helperText={props.error?.message}
              label={props.label}
              required={!!props.rules?.required}
            />
          )}
          {...(props.renderInput && { renderInput: props.renderInput })}
          onChange={(_, data) => {
            props.onChange ? onChange(props.onChange(data as Value)) : onChange(data);
          }}
        />
      )}
    />
  );
}

export { AutocompleteWrapper };
