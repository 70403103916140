import { styled } from '@mui/system';

const TabsContainer = styled('div')(() => ({
  borderBottom: 1,
  borderColor: 'divider',
  height: '100%'
}));

const TabsContentContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  height: `calc(100% - 48px)`
}));

export { TabsContainer, TabsContentContainer };
