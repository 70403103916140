import { useGlobalContext } from '@linx-ui/shared/context/globalContext';
import type {
  CommonActionType,
  FeaturesNames,
  PosActionType,
  PrescriptionActionType,
  ReportActionType,
  TextMessageActionType
} from '@linx-ui/shared/core/auth/types';

export const useFeaturePermissions = () => {
  const {
    auth: { permissions }
  } = useGlobalContext();

  const hasPermissionTo = <T extends FeaturesNames>(featureName: T) => {
    type ActionType = T extends 'prescription'
      ? PrescriptionActionType
      : T extends 'report'
        ? ReportActionType
        : T extends 'pos'
          ? PosActionType
          : T extends 'text-message'
            ? TextMessageActionType
            : CommonActionType;
    return (action: ActionType): boolean => permissions[featureName]?.[action] ?? false;
  };

  return hasPermissionTo;
};
