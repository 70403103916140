import { SmsOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { useCallback, useState } from 'react';
import type { SendSMSProps } from './types';
import { SendSMSDrawer } from './sendSMSDrawer';

const SendSMS = ({ disabled, defaultPhoneNumber, headerKeys, patientId }: SendSMSProps) => {
  const [open, setIsOpen] = useState<boolean>(false);

  const toggle = useCallback(() => {
    setIsOpen(!open);
  }, [open]);

  const triggerBtn = (
    <Button
      onClick={toggle}
      variant="outlined"
      data-testid="send-sms-btn"
      startIcon={<SmsOutlined />}
      color="secondary"
      disabled={disabled}
    >
      SEND SMS
    </Button>
  );

  return (
    <React.Fragment>
      {triggerBtn}
      {open && (
        <SendSMSDrawer
          open={open}
          toggle={toggle}
          title="Send SMS"
          headerKeys={headerKeys}
          patientId={patientId}
          defaultPhoneNumber={defaultPhoneNumber}
        />
      )}
    </React.Fragment>
  );
};

export { SendSMS };
