import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import {
  GridLayoutContainer,
  GridLayoutItem,
  GridLayoutItemWithXs12sm12md12lg12xl12
} from '@linx-ui/shared/components/gridLayout';
import { TextFieldWrapper } from '@linx-ui/shared/components/textFieldWrapper';
import { useFeaturePermissions } from '@linx-ui/shared/core';
import { useReactMutation } from '@linx-ui/shared/hooks/api/useReactMutation';
import { useFormWrapperWithoutSteps } from '@linx-ui/shared/hooks/useFormWrapperWithoutSteps';
import { SwitchWrapper } from '../../switchWrapper';
import { labels } from './labels';
import { DividerStyled } from './Notes.styled';
import { NotesListing } from './notesListing';
import { type NotesProps } from './types';
import { validationRules } from './validationRules';

const Notes: React.FC<NotesProps> = ({
  entityId,
  url,
  headerKeys,
  showErxAnnotaionToggle,
  noteInputRef,
  erxToggleDefaultValue
}: NotesProps) => {
  const canUpdate = useFeaturePermissions()('note')('update');
  const canUCreate = useFeaturePermissions()('note')('create');
  const canCreateUpdate = canUpdate || canUCreate;

  const onSuccess = () => {
    setForceRender(!forceRender);
    setValueId('', undefined, false);
  };
  const [forceRender, setForceRender] = useState(false);
  const [id, setId] = useState<string | undefined>();
  const [bkpValue, setBkpValue] = useState<{ text: string; isErxAnnotation: boolean | undefined }>({
    text: '',
    isErxAnnotation: undefined
  });
  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);

  const { isPending, mutate } = useReactMutation({
    url: `${url}/${String(entityId)}/notes`,
    headerKeys,
    method: 'POST',
    successMsg: labels.savedSuccessfully,
    onSuccess
  });

  const { control, getValidationError, isValid, getValues, reset, watch } = useFormWrapperWithoutSteps();

  const watchText = watch('text');
  const erxToggleVal = watch('isErxAnnotation');

  const setValueId = (text: string, id: string | undefined, isErxAnnotation: boolean | undefined) => {
    reset((formValues) => {
      return { ...formValues, text, isErxAnnotation };
    });
    setId(id);
  };

  useEffect(() => {
    setSaveDisabled(bkpValue.text === watchText?.trim() && bkpValue.isErxAnnotation === erxToggleVal);
  }, [watchText, erxToggleVal]);

  const onEdit = (text: string, id: string, isErxAnnotation: boolean | undefined) => {
    setBkpValue({ text, isErxAnnotation });
    setValueId(text, id, isErxAnnotation);
  };

  const onDelete = (idToDelete: string) => {
    if (id === idToDelete) {
      setForceRender(!forceRender);
      setValueId('', undefined, false);
    }
  };

  const onSave = () => {
    const request = { ...getValues(), entityId, id };
    mutate(request);
  };

  const onCancel = () => {
    setValueId('', undefined, false);
  };

  const isSaveDisabled = useMemo(() => {
    return !isValid || isPending || saveDisabled;
  }, [isValid, isPending, canCreateUpdate, saveDisabled]);

  return (
    <GridLayoutContainer height="100%">
      {canCreateUpdate && (
        <>
          <GridLayoutItem xs={12}>
            <TextFieldWrapper
              data-testid="note-input"
              name="text"
              control={control}
              label={labels.note}
              rules={validationRules.text}
              error={getValidationError('text')}
              helperText={labels.max1000Chars}
              multiline
              rows={3}
              inputRef={noteInputRef}
            />
          </GridLayoutItem>
          <GridLayoutItemWithXs12sm12md12lg12xl12>
            {showErxAnnotaionToggle && (
              <SwitchWrapper
                value={erxToggleDefaultValue}
                name="isErxAnnotation"
                label="ERx Annotation"
                control={control}
              />
            )}
          </GridLayoutItemWithXs12sm12md12lg12xl12>

          <GridLayoutItem xs={12} display="flex" justifyContent="flex-end">
            {id ? (
              <Button color="secondary" onClick={onCancel} disabled={isPending} data-testid="btn-cancel">
                {labels.cancel}
              </Button>
            ) : null}
            <Button
              variant="contained"
              color="secondary"
              data-testid="btn-save"
              startIcon={!id ? <Add /> : undefined}
              onClick={onSave}
              disabled={isSaveDisabled}
            >
              {!id ? labels.addNote : labels.save}
            </Button>
          </GridLayoutItem>
          <GridLayoutItem xs={12}>
            <DividerStyled />
          </GridLayoutItem>
        </>
      )}

      <GridLayoutItem xs={12} style={{ height: 'calc(100% - 200px)' }}>
        <NotesListing
          url={url}
          forceRender={forceRender}
          entityId={entityId}
          headerKeys={headerKeys}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </GridLayoutItem>
    </GridLayoutContainer>
  );
};

export { Notes };
