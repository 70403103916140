import { ArrowBack } from '@mui/icons-material';
import { useMemo } from 'react';
import { useToggle } from 'usehooks-ts';
import { TabsCreator, type TabCreatorItem } from '@linx-ui/shared/components/tabsCreator';
import { DrawerContainer } from '@linx-ui/shared/components/drawerContainer';
import { DRAWER_WIDTH_XSM, SharedShortcutIds } from '@linx-ui/shared/constants';
import { useFeaturePermissions } from '@linx-ui/shared/core';
import { getTriggerBtn } from '@linx-ui/shared/utils/helpers';
import { Documents } from './documents';
import { Notes } from './notes/Notes';
import { NotesAndDocumentsCnt } from './NotesDocuments.styled';
import { type NotesAndDocumentsProps } from './types';

const NotesAndDocuments: React.FC<NotesAndDocumentsProps> = ({
  url,
  entityId,
  headerKeys,
  canViewDocuments = false,
  featureName,
  showErxAnnotaionToggle
}: NotesAndDocumentsProps) => {
  const [value, toggle] = useToggle();
  const hasPermissionToReadNotes = useFeaturePermissions()('note')('read');

  const accordians = useMemo(() => {
    const items: TabCreatorItem[] = [];
    if (hasPermissionToReadNotes) {
      items.push({
        key: 'notes',
        title: 'Notes',
        component: (
          <Notes
            url={url}
            entityId={entityId}
            headerKeys={headerKeys}
            showErxAnnotaionToggle={showErxAnnotaionToggle}
          />
        )
      });
    }
    if (canViewDocuments) {
      items.push({
        key: 'documents',
        title: 'Documents',
        component: (
          <Documents
            featureName={featureName}
            showUploadedFiles={true}
            url={url}
            entityId={entityId}
            headerKeys={headerKeys}
            fileInputProps={{
              accept: { 'image/png': [], 'image/jpeg': [], 'application/pdf': [] },
              title: 'DRAG & DROP OR BROWSE FILES',
              subTitle: 'Only JPEG, PNG and PDF files with max size of 10 MB',
              fileTypeErrorMsg: 'Only JPEG, PNG and PDF file types are allowed.'
            }}
          />
        )
      });
    }

    return items;
  }, [hasPermissionToReadNotes, headerKeys, entityId, url]);

  const triggerBtn = getTriggerBtn({
    testId: 'btn-notes-documents',
    text: 'Notes & Docs',
    color: 'secondary',
    PrefixIcon: ArrowBack
  });

  return (
    <DrawerContainer
      triggerBtn={triggerBtn}
      openDrawer={value}
      toggle={toggle}
      title="Notes & Documents"
      width={DRAWER_WIDTH_XSM}
      shortcutId={SharedShortcutIds.OPEN_NOTES_AND_DOCUMENTS}
    >
      <NotesAndDocumentsCnt>
        <TabsCreator items={accordians} selected="notes" />
      </NotesAndDocumentsCnt>
    </DrawerContainer>
  );
};

export { NotesAndDocuments };
