import { useAuth0 } from '@auth0/auth0-react';
import type { AxiosRequestConfig } from 'axios';
import { getHeadersFromKeys } from '@linx-ui/shared/utils';
import { axios } from '@linx-ui/shared/utils/axios';
import type { ApiErrorResponse } from '../useQueryHelpers/types';
import { useQueryHelpers } from '../useQueryHelpers';
import type { UseAxiosParams } from './types';

export const useAxios = <Data, InterceptedData = Data, Error = ApiErrorResponse>(
  config: UseAxiosParams<Data, InterceptedData, Error>
) => {
  const {
    successMsg,
    successTitle,
    errorMsg,
    severity,
    select,
    headerKeys = [],
    showErrorToast = true,
    showSuccessToast = true,
    shouldRedirectToErrorPage = true,
    headers: defaultHeaders = { 'Content-Type': 'application/json' }
  } = config;
  const auth = useAuth0();
  const headers = getHeadersFromKeys(headerKeys);
  const { raiseSuccessToast, raiseErrorToast, navigateToErrorPage } = useQueryHelpers({
    successTitle,
    successMsg,
    errorMsg,
    severity,
    showErrorToast,
    showSuccessToast
  });

  return async (axiosConfig: AxiosRequestConfig) => {
    try {
      const response = await axios<Data>({
        ...axiosConfig,
        headers: {
          ...{ ...headers, ...defaultHeaders, ...axiosConfig.headers },
          Authorization: defaultHeaders.Authorization ?? `Bearer ${await auth.getAccessTokenSilently()}`
        }
      });
      if ((response as unknown as ApiErrorResponse)?.error) {
        return await Promise.reject(response);
      }
      const data = (select ? select(response as Data) : response) as InterceptedData;
      raiseSuccessToast(data);
      return data;
    } catch (error: any) {
      raiseErrorToast(error as Error);
      shouldRedirectToErrorPage && navigateToErrorPage(error);
    }
  };
};
