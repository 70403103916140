import { Add } from '@mui/icons-material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { DRAWER_WIDTH_MD } from '@linx-ui/shared/constants';
import { useFeaturePermissions } from '@linx-ui/shared/core';
import { getTriggerBtn } from '@linx-ui/shared/utils/helpers';
import { useInfiniteReactQueryList } from '@linx-ui/shared/hooks/api/useInfiniteReactQueryList';
import { CancelAction, SubmitAction } from '../Actions';
import { CreateWrapper } from '../create';
import { DrawerContainer } from '../drawerContainer';
import { TableWrapper } from '../styles';
import { TableWithData } from './TableWithData';
import { type PickerProps } from './types';

const _Picker = <T extends { id?: string }, U>({
  name,
  url,
  title,
  columns,
  open,
  togglePicker,
  onDone,
  onCancel,
  selectedRowIds,
  enableSearch,
  enableMultiRowSelection,
  enableSingleRowSelection,
  disableRowSelectionFn,
  width,
  testId,
  headerKeys = [],
  tableToolbarComponents,
  CreateEntity,
  createEntityLabels,
  featureName,
  implicitFormProperties = [],
  dataTranslator,
  serverSide,
  closeOnDone = true,
  submitActionName,
  closeActionName,
  searchPlaceholder,
  extraFetchParams,
  enableSorting,
  rowIdentifier = 'id'
}: PickerProps<T, U>) => {
  const [selectedIds, setSelectedIds] = useState<string[]>(selectedRowIds ?? []);

  const hasCreatePermission = useFeaturePermissions()(featureName)('create');

  const { items, fetchNextPage, isLoading, isFetchingNextPage, refetch, onSearch, onSort } =
    useInfiniteReactQueryList<any>({
      url,
      headerKeys,
      params: {
        ...extraFetchParams,
        limit: serverSide ? 25 : -1
      },
      enabled: open
    });

  const onRowSelection = (ids: string[]) => {
    setSelectedIds(ids);
  };

  const onSubmit = () => {
    onDone?.(items.filter((item) => selectedIds.includes(item[rowIdentifier])));
    closeOnDone && togglePicker();
  };

  const onCancelled = useCallback(() => {
    onCancel?.();
    togglePicker();
  }, [onCancel, togglePicker]);

  const onCreateSuccess = useCallback(async () => {
    open && (await refetch());
  }, [open]);

  useEffect(() => {
    const allIds = items.map((item) => item.id);
    setSelectedIds((selectedIds) => selectedIds?.filter((id) => allIds.includes(id)) ?? []);
  }, [items]);

  const tData = useMemo(() => {
    return dataTranslator?.(items || []) ?? (items || []);
  }, [items, dataTranslator]);

  const addEntityAction = useMemo(() => {
    return CreateEntity && createEntityLabels ? (
      <CreateWrapper<T>
        {...createEntityLabels}
        implicitFormProperties={implicitFormProperties}
        url={url}
        CreateComponent={CreateEntity}
        successCallback={onCreateSuccess}
        headerKeys={headerKeys}
        drawerWidth={DRAWER_WIDTH_MD}
        triggerBtn={getTriggerBtn({
          text: createEntityLabels.createButton,
          PrefixIcon: Add,
          disabled: !hasCreatePermission
        })}
        disabled={!hasCreatePermission}
      />
    ) : undefined;
  }, [CreateEntity, createEntityLabels, implicitFormProperties, url, onCreateSuccess, headerKeys, featureName]);

  const cancelAction = useMemo(
    () => CancelAction({ onClick: onCancelled ?? togglePicker, btnText: closeActionName }),
    [onCancelled, togglePicker, closeActionName]
  );

  const submitAction = useMemo(
    () => <SubmitAction onClick={onSubmit} disabled={!selectedIds?.length} btnText={submitActionName} />,
    [selectedIds, onSubmit, submitActionName]
  );

  const actionBtnsRight = useMemo(() => {
    return [cancelAction, submitAction];
  }, [cancelAction, submitAction]);

  const actionBtnsLeft = useMemo(() => {
    return addEntityAction && [addEntityAction];
  }, [addEntityAction]);

  if (!open) return null;

  return (
    <DrawerContainer
      openDrawer={open}
      toggle={togglePicker}
      title={title}
      actionBtnsRight={actionBtnsRight}
      actionBtnsLeft={actionBtnsLeft}
      closeActionCallback={onCancelled}
      width={width ?? DRAWER_WIDTH_MD}
    >
      <TableWrapper>
        <TableWithData
          name={name}
          testId={testId}
          data={tData}
          columns={columns}
          rowIdentifier={rowIdentifier}
          enableSingleRowSelection={enableSingleRowSelection}
          enableMultiRowSelection={enableMultiRowSelection}
          onRowSelection={onRowSelection}
          loading={isFetchingNextPage || isLoading}
          enableSearch={enableSearch}
          selectedRowIds={selectedIds}
          disableRowSelectionFn={disableRowSelectionFn}
          tableToolbarComponents={tableToolbarComponents}
          serverSide={serverSide}
          onRowsScrollEnd={fetchNextPage}
          onSearch={serverSide ? onSearch : undefined}
          onSort={onSort}
          searchPlaceholder={searchPlaceholder}
          enableSorting={enableSorting}
          disableQuerySearch={true}
        />
      </TableWrapper>
    </DrawerContainer>
  );
};

export const Picker = memo(_Picker) as typeof _Picker;
