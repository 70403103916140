import { HelpOutline } from '@mui/icons-material';
import { Chip, IconButton } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { GridLayoutContainer, GridLayoutItemWithXs12sm12md12lg6xl6 } from '@linx-ui/shared/components/gridLayout';
import { codeToCharMap, SharedShortcutIds, type KeyboardShortcut } from '@linx-ui/shared/constants';
import { useKeyboard, useKeyboardTrigger } from '@linx-ui/shared/context/keyboardContext';
import { theme } from '@linx-ui/shared/theming';
import { MenuStyled } from '../styles';
import { ShortcutItem, ShortcutSection } from './ShortcutHelper.styled';

const ShortcutHelper = () => {
  const { sections, getActiveShortcuts } = useKeyboard();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleMenuClose = (): void => {
    setIsMenuOpen(false);
  };

  const handleOpen = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  const toggleMenu = useCallback((): void => {
    setIsMenuOpen((prev) => !prev);
  }, []);

  useKeyboardTrigger(SharedShortcutIds.SHORTCUT_HELP_MENU, toggleMenu);

  const hasEnabledShortcuts = (section: string): boolean => {
    return getActiveShortcuts().some((shortcut) => shortcut.section === section);
  };

  const getShortcutLabel = useCallback((shortcut: KeyboardShortcut) => {
    return shortcut.shortcut.map((x) => codeToCharMap[x]?.toUpperCase() ?? x.toUpperCase()).join(' + ');
  }, []);

  return (
    <>
      <IconButton
        ref={anchorRef}
        size="large"
        aria-label="open shortcuts help button"
        onClick={handleOpen}
        data-testid="open-shortcuts-help-btn"
      >
        <HelpOutline />
      </IconButton>
      <MenuStyled
        anchorEl={anchorRef.current}
        aria-label="account menu"
        data-testid="account-menu"
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <GridLayoutContainer spacing={theme.spacing(3)} p={theme.spacing(2)}>
          {Object.values(sections).map((section) =>
            hasEnabledShortcuts(section) ? (
              <GridLayoutItemWithXs12sm12md12lg6xl6 key={section}>
                <ShortcutSection>{section}</ShortcutSection>
                {getActiveShortcuts()
                  .filter((s) => s.section === section)
                  .map((shortcut) => (
                    <ShortcutItem key={shortcut.id}>
                      <div>{shortcut.description}</div>
                      <div>
                        <Chip label={getShortcutLabel(shortcut)} color="info" sx={{ fontWeight: 'bold' }} />
                      </div>
                    </ShortcutItem>
                  ))}
              </GridLayoutItemWithXs12sm12md12lg6xl6>
            ) : null
          )}
        </GridLayoutContainer>
      </MenuStyled>
    </>
  );
};

export { ShortcutHelper };
