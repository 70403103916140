import { memo, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dialog, DialogContent } from '@mui/material';
import ReactDiffViewer from 'react-diff-viewer';
import { ChangeHistoryTableCnt } from '@linx-ui/shared/components/styles';
import { Table } from '@linx-ui/shared/components/table';
import { TABLE_NAMES } from '@linx-ui/shared/constants/tableNames';
import { useReactQueryList } from '@linx-ui/shared/hooks/api/useReactQueryList';
import { columns, getFormattedChangeHistoryValue } from './columns';
import type { History, Props } from './types';

export const ChangeHistory = memo(({ url, headerKeys = [] }: Props) => {
  const { id } = useParams<{ id: string }>();
  const [rowData, setRowData] = useState<History | null>(null);

  const { data, isLoading } = useReactQueryList<History>({
    url: `${url}/${id}/history`,
    headerKeys
  });

  const { oldValue, newValue } = useMemo(
    () => ({
      oldValue: getFormattedChangeHistoryValue(rowData?.oldValue, rowData?.propertyName),
      newValue: getFormattedChangeHistoryValue(rowData?.newValue, rowData?.propertyName)
    }),
    [rowData]
  );

  return (
    <>
      <ChangeHistoryTableCnt>
        <Table
          name={TABLE_NAMES.CHANGE_HISTORY}
          testId="test-change-history"
          columns={columns}
          data={data.items}
          loading={isLoading}
          onRowClick={setRowData}
          enableHideColumns
          enableGlobalFilter
        />
      </ChangeHistoryTableCnt>
      <Dialog open={!!rowData} onClose={() => setRowData(null)} maxWidth="lg">
        <DialogContent>
          <ReactDiffViewer oldValue={oldValue} newValue={newValue} />
        </DialogContent>
      </Dialog>
    </>
  );
});
ChangeHistory.displayName = 'ChangeHistory';

export default ChangeHistory;
