import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Controller } from 'react-hook-form';
import type { DateTimePickerWrapperProps } from './types';

const DateTimePickerWrapper = <T,>({ size = 'small', ...props }: DateTimePickerWrapperProps<T>): JSX.Element => (
  <Controller
    name={props.name}
    control={props.control}
    rules={props.rules}
    defaultValue={props.value ?? null}
    render={({ field: { value, onChange, onBlur, name, ...rest } }) => (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateTimePicker
          {...rest}
          data-testid={props.dataTestId}
          label={props.label}
          value={props.inputFormatter(props?.value)}
          disableFuture={props.disableFuture}
          onChange={(event) => {
            if (event) {
              event?.isValid() ? onChange(props.outputFormatter(event)) : onChange('Invalid date');
            } else {
              onChange(event);
            }
          }}
          slotProps={{
            textField: {
              onBlur,
              name,
              size,
              fullWidth: true,
              required: !!props?.rules?.required,
              error: props.error?.hasError,
              helperText: props.error?.message
            }
          }}
        />
      </LocalizationProvider>
    )}
  />
);

export { DateTimePickerWrapper };
