import { SharedShortcutIds } from './shortcutIds';
import type { ShortcutConfig } from './types';

export const SharedGeneralSection: ShortcutConfig = [
  {
    id: SharedShortcutIds.CREATE_NEW_ENTITY,
    description: 'Create Entity',
    shortcut: ['alt', 'KeyN'],
    trigger: true,
    section: 'General',
    enableFor: '/'
  },
  {
    id: SharedShortcutIds.UPDATE_ENTITY,
    description: 'Update Entity',
    shortcut: ['alt', 'shift', 'KeyE'],
    trigger: true,
    section: 'General',
    enableFor: '/'
  },
  {
    id: SharedShortcutIds.OPEN_NOTES_AND_DOCUMENTS,
    description: 'Open Notes and Documents',
    shortcut: ['alt', 'shift', 'KeyN'],
    trigger: true,
    section: 'General',
    enableFor: '/'
  },
  {
    id: SharedShortcutIds.QUICK_FIND,
    description: 'Quick Find Shortcuts',
    shortcut: ['ctrl', 'KeyP'],
    trigger: true,
    section: 'General',
    enableFor: '/'
  },
  {
    id: SharedShortcutIds.SHORTCUT_HELP_MENU,
    description: 'Show shortcuts help',
    shortcut: ['ctrl', 'Slash'],
    trigger: true,
    section: 'General',
    enableFor: '/'
  }
];
