import { FileUploadOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useCallback } from 'react';
import Dropzone, { type FileRejection } from 'react-dropzone';
import { theme } from '@linx-ui/shared/theming';
import { FileUploadContainer } from './FileInput.styled';
import { type FileInputProps } from './types';

const FileInput = ({
  title,
  subTitle,
  disabled,
  icon = (
    <FileUploadOutlined style={{ color: !disabled ? theme.palette.secondary.main : theme.palette.text.disabled }} />
  ),
  multiple,
  accept,
  onFilesChosen,
  maxSize
}: FileInputProps) => {
  const onFilesDropped = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      onFilesChosen?.(acceptedFiles, rejectedFiles);
    },
    [onFilesChosen]
  );

  return (
    <Dropzone multiple={multiple} onDrop={onFilesDropped} maxSize={maxSize} accept={accept} disabled={disabled}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div {...getRootProps()} data-testid="file-drop-zone-cnt">
          <input data-testid="file-drop-zone-input" {...getInputProps()} />
          <FileUploadContainer focused={isDragActive}>
            {icon}
            <Typography variant="button" color={!disabled ? theme.palette.secondary.main : theme.palette.text.disabled}>
              {title}
            </Typography>
            <Typography
              variant="caption"
              color={!disabled ? theme.palette.secondary.main : theme.palette.text.disabled}
            >
              {subTitle}
            </Typography>
          </FileUploadContainer>
        </div>
      )}
    </Dropzone>
  );
};

export { FileInput };
